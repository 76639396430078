import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './common/style/variable.less'
import 'vue2-animate/dist/vue2-animate.min.css'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(Element)

// 计算rem
function setWidth() {

  !(function (n) {
    var e = n.document,
      t = e.documentElement,
      i = 750,
      d = i / 10,
      o = "orientationchange" in n ? "orientationchange" : "resize",
      a = function () {
        var n = t.clientWidth || 320;
        n > 750 && (n = 750);
        t.style.fontSize = n / d + "px";
      };
    e.addEventListener &&
      (n.addEventListener(o, a, !1),
        e.addEventListener("DOMContentLoaded", a, !1));
  })(window);

}
setWidth()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
