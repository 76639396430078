<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
// import Navbar from './components/Navbar'

export default {
}
</script>
